import React, { Component } from "react"
import Vid1 from "../../../content/assets/videos/video_1.webm"
import Video1 from "../../../content/assets/videos/vid1.mp4"
import Vid2 from "../../../content/assets/videos/video_2.webm"
import Video2 from "../../../content/assets/videos/vid2.mp4"
import Vid3 from "../../../content/assets/videos/video_3.webm"
import Video3 from "../../../content/assets/videos/vid3.mp4"
import Vid4 from "../../../content/assets/videos/video_4.webm"
import Video4 from "../../../content/assets/videos/vid4.mp4"
import HomePageComponent from "../home-page/homePageComponent"

class VideoPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videos: [],
      safariVids: [],
      randomIndex: null,
    }
  }

  randomVideoPicker() {
    const randomNumber = Math.floor(Math.random() * 4)
    this.setState({ randomIndex: randomNumber })
  }

  componentDidMount() {
    this.setState({
      videos: [Vid1, Vid2, Vid3, Vid4],
      safariVids: [Video1, Video2, Video3, Video4],
    })
    this.randomVideoPicker()
  }
  render() {
    const randomVideo = this.state.randomIndex
    return (
      <>
        <HomePageComponent
          vid={this.state.videos[randomVideo]}
          safariVids={this.state.safariVids[randomVideo]}
        />
      </>
    )
  }
}

export default VideoPicker
