import {keyframes} from 'styled-components'

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.15;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
`