import React from "react"
import styled from "styled-components"
import MainHeader from "../header-components/mainHeader"
import SocialFooter from "../socialBox/socialFooter"
import SEO from "../seo"
import Layout from "../layout"

const HomePageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 125px 1fr 75px;
  grid-template-columns: 1fr;
`

const VideoPlayer = styled.video`
  grid-area: 1/1/4/2;
  object-fit: cover;
  height: 100vh;
  width: 100vw;
`

const HomePageComponent = ({ vid, safariVids }) => (
  <Layout>
    <SEO title="home" />
    <HomePageContainer>
      <MainHeader />
      <VideoPlayer
        src={vid}
        type="video/webm"
        src={safariVids}
        type="video/mp4"
        autoPlay
        muted
        loop
        controls={false}
        allowFullScreen
      ></VideoPlayer>
      <SocialFooter />
    </HomePageContainer>
  </Layout>
)

export default HomePageComponent
