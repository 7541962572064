import React from "react"
import styled from "styled-components"
import { colors } from "../colors/colors"
import LandingLogo from "../SVGs/landingLogo"
import { FadeIn } from "../fades/keyFrames"

const LandingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 10px;
  background: ${colors.black};

  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-row: repeat(3, 1fr);
  }
`

const LogoHolder = styled.div`
  grid-area: 2 / 3 / 5 / 4;
  align-self: start;
  justify-self: center;
  width: 275px;
  height: 275px;
  align-items: center;
  justify-content: center;
  animation: ${FadeIn};
  animation-duration: 2s;
  animation-timing-function: ease-in;

  @media (max-width: 1000px) {
    grid-area: 2/2/3/3;
    height: 200px;
    width: 200px;
  }
`

const BlackLandingPage = () => {
  return (
    <LandingContainer>
      <LogoHolder>
        <LandingLogo />
      </LogoHolder>
    </LandingContainer>
  )
}

export default BlackLandingPage
