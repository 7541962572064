import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlackLandingPage from "../components/landings/blackLanding"
import FadeIn from "../components/fades/fadeIn"
import VideoPicker from "../components/video/videoPickerComponent"

class BlogIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLanding: false,
    }
  }

  changeLandingPage() {
    this.setState({ showLanding: true })
    clearTimeout(this.timer)
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showLanding: true })
    }, 3000)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        {this.state.showLanding ? (
          <FadeIn>
            <VideoPicker />
          </FadeIn>
        ) : (
          <BlackLandingPage />
        )}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
