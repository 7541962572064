import React from 'react';
import styled from 'styled-components'
import {FadeIn} from './keyFrames'

const Wrapper = styled.div`
  width: 100vw;
  animation: ${FadeIn};
  animation-duration: 2s;
  animation-timing-function: ease-in; 
`

const FadeLanding = ({children}) => {
    return ( 
        <Wrapper>
            {children}
        </Wrapper>
     );
}
 
export default FadeLanding;